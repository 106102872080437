<template>
  <DetailTemplate
    :customClass="'quotation-detail detail-page'"
    v-if="getPermission('quotation:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="130"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ detail.job_title }}
          </h1>
          <CustomStatus
            :status.sync="detail.status"
            endpoint="quotation/status"
          ></CustomStatus>
          <v-chip
            v-if="detail.is_approved"
            color="green white--text"
            label
            class="mx-2 font-weight-600 text-uppercase d-table"
            >Approved</v-chip
          >
          <template v-if="detail.is_revised">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  color="red lighten-4"
                  class="mx-2 black--text font-weight-600 d-table"
                  label
                >
                  REVISED
                </v-chip>
              </template>
              <span>Quotation is Revised</span>
            </v-tooltip>
          </template>
          <template v-if="lodash.isEmpty(detail.revise) === false">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  color="teal lighten-4"
                  class="mx-2 black--text font-weight-600 d-table"
                  label
                  link
                  v-on:click="
                    $router.push(
                      getDefaultRoute('quotation.detail', {
                        params: {
                          id: detail.revise.id
                        }
                      })
                    )
                  "
                >
                  REVISION
                </v-chip>
              </template>
              <span>Revision of {{ detail.revise.barcode }}</span>
            </v-tooltip>
          </template>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="detail.status == 1">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
              v-on:click="updateMoreAction('edit')"
            >
              <v-icon left>mdi-pencil</v-icon> Edit
            </v-btn>
          </template>
          <span>Click here to edit Quote</span>
        </v-tooltip>
      </template>
      <v-tooltip top content-class="custom-top-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
            v-on:click="updateMoreAction('revise')"
          >
            <v-icon left>mdi-update</v-icon> Revise
          </v-btn>
        </template>
        <span>Click here to revise Quote</span>
      </v-tooltip>
      <v-menu
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 75vh; position: relative"
      > -->
      <v-container fluid>
        <v-row dense>
          <v-col cols="5" class="pb-0">
            <v-container fluid>
              <v-list flat>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                          >
                            <router-link
                              :to="
                                getDefaultRoute('customer.detail', {
                                  params: {
                                    id: customer.customer
                                  }
                                })
                              "
                              >{{ detail.attention }}</router-link
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action
                          link
                          class="m-0 ml-4"
                          v-on:click="
                            $router.push(
                              getDefaultRoute('customer.detail', {
                                params: {
                                  id: customer.customer
                                }
                              })
                            )
                          "
                        >
                          <v-icon class="color-custom-blue"
                            >mdi-link mdi-rotate-135</v-icon
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <span>Attention</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-home-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                          >
                            <router-link
                              :to="
                                getDefaultRoute('customer.detail', {
                                  params: {
                                    id: customer.customer
                                  }
                                })
                              "
                              >{{ customer.display_name }}</router-link
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action
                          link
                          class="m-0 ml-4"
                          v-on:click="
                            $router.push(
                              getDefaultRoute('customer.detail', {
                                params: {
                                  id: customer.customer
                                }
                              })
                            )
                          "
                        >
                          <v-icon class="color-custom-blue"
                            >mdi-link mdi-rotate-135</v-icon
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <span>Display Name</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-email</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                          >
                            <a
                              :href="'mailto:' + contact_person.primary_email"
                              >{{ contact_person.primary_email }}</a
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Contact Email</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-phone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                          >
                            <a :href="'tel:' + contact_person.primary_phone">{{
                              contact_person.primary_phone
                            }}</a>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Contact Phone</span>
                  </v-tooltip>
                </template>
                <template v-if="false">
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="py-1" v-bind="attrs" v-on="on">
                          <v-list-item-icon class="margin-auto-zero mr-4">
                            <v-icon class="font-size-28"
                              >mdi-home-map-marker</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-content
                            class="p-0"
                            v-if="property.property_address"
                          >
                            <v-list-item-title
                              class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                            >
                              <a
                                target="_blank"
                                :href="
                                  'https://maps.google.com/?q=' +
                                    property.property_address
                                "
                                >{{ property.property_address }}</a
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <span>Property Address</span>
                    </v-tooltip>
                  </template>
                </template>
                <template v-if="BillingDetail">
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="py-1" v-bind="attrs" v-on="on">
                          <v-list-item-icon class="margin-auto-zero mr-4">
                            <v-icon class="font-size-28"
                              >mdi-home-currency-usd</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-content class="p-0">
                            <v-list-item-title
                              class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                            >
                              <a
                                target="_blank"
                                :href="
                                  'https://maps.google.com/?q=' + BillingDetail
                                "
                                >{{ BillingDetail }}</a
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <span>Company Address</span>
                    </v-tooltip>
                  </template>
                </template>
              </v-list>
            </v-container>
          </v-col>
          <v-col cols="7" class="pb-0 gray-background pl-4">
            <v-container fluid>
              <v-row dense>
                <v-col md="12">
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <p
                    v-else
                    class="m-0 font-weight-700 font-size-18 pt-3 pb-1 color-custom-blue"
                    v-on:dblclick="updateField('job_title')"
                  >
                    <template v-if="updateDetailDialog.job_title">
                      <v-text-field
                        ref="job_title"
                        v-model.trim="updateDetail.job_title"
                        dense
                        filled
                        color="cyan"
                        label="Job Title"
                        :rules="[
                          validateRules.minLength(
                            updateDetail.job_title,
                            'Job Title',
                            1
                          ),
                          validateRules.maxLength(
                            updateDetail.job_title,
                            'Job Title',
                            100
                          )
                        ]"
                        solo
                        flat
                        hide-details
                        :loading="particuleFieldLoading"
                        :disabled="particuleFieldLoading"
                        @keydown.esc="onEscParticularField"
                        @keydown.enter="updateParticularField"
                      ></v-text-field>
                    </template>
                    <template v-else>
                      <span
                        :class="{
                          'custom-bottom-border': canUpdateQuotation
                        }"
                        >{{ detail.job_title }}</span
                      >
                      <UpdateTooltip v-if="canUpdateQuotation"></UpdateTooltip>
                    </template>
                  </p>
                </v-col>
                <v-col md="12">
                  <template v-if="pageLoading">
                    <v-skeleton-loader
                      v-for="(d, i) in 4"
                      :key="i"
                      class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                      :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <table class="width-100">
                      <tr>
                        <td class="font-size-18 pt-3 pb-1" width="200">
                          Quotation Ref
                        </td>
                        <td class="font-weight-600 font-size-18 pt-3 pb-1">
                          {{ detail.barcode }}
                        </td>
                      </tr>
                      <tr v-if="detail.project">
                        <td class="font-size-18 py-1" width="200">Project #</td>
                        <td class="font-weight-600 font-size-18 py-1">
                          {{ detail.project.barcode }} -
                          {{ detail.project.name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 pb-1" width="200">
                          Rate opportunity
                        </td>
                        <td
                          class="font-weight-600 font-size-18 pb-1"
                          v-on:dblclick="updateField('rating')"
                        >
                          <template v-if="updateDetailDialog.rating">
                            <v-rating
                              ref="rating"
                              class="remove-y-padding"
                              v-model.trim="updateDetail.rating"
                              background-color="orange lighten-3"
                              color="orange"
                              :loading="particuleFieldLoading"
                              :disabled="particuleFieldLoading"
                              @keydown.esc="onEscParticularField"
                              @input="updateParticularField"
                              @keydown.enter="updateParticularField"
                            ></v-rating>
                          </template>
                          <template v-else>
                            <span class="d-inline-block"
                              ><v-rating
                                readonly
                                class="remove-y-padding"
                                v-model.trim="detail.rating"
                                background-color="orange lighten-3"
                                color="orange"
                              ></v-rating
                            ></span>
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          Reference #
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                          v-on:dblclick="updateField('reference')"
                        >
                          <template v-if="updateDetailDialog.reference">
                            <v-text-field
                              ref="reference"
                              v-model.trim="updateDetail.reference"
                              dense
                              filled
                              color="cyan"
                              label="Reference #"
                              :rules="[
                                validateRules.minLength(
                                  updateDetail.reference,
                                  'Reference',
                                  1
                                ),
                                validateRules.maxLength(
                                  updateDetail.reference,
                                  'Reference',
                                  100
                                )
                              ]"
                              solo
                              flat
                              hide-details
                              :loading="particuleFieldLoading"
                              :disabled="particuleFieldLoading"
                              @keydown.esc="onEscParticularField"
                              @keydown.enter="updateParticularField"
                            ></v-text-field>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation
                              }"
                              >{{ detail.reference }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <tr v-if="false">
                        <td class="font-size-18 py-1" width="200">
                          Sales representative
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                          v-on:dblclick="updateField('sales')"
                        >
                          <template v-if="updateDetailDialog.sales">
                            <v-text-field
                              ref="sales"
                              v-model.trim="updateDetail.sales"
                              dense
                              filled
                              color="cyan"
                              label="Sales representative"
                              :rules="[
                                validateRules.minLength(
                                  updateDetail.sales,
                                  'Sales representative',
                                  1
                                ),
                                validateRules.maxLength(
                                  updateDetail.sales,
                                  'Sales representative',
                                  100
                                )
                              ]"
                              solo
                              flat
                              hide-details
                              :loading="particuleFieldLoading"
                              :disabled="particuleFieldLoading"
                              @keydown.esc="onEscParticularField"
                              @keydown.enter="updateParticularField"
                            ></v-text-field>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation
                              }"
                              >{{ detail.sales }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          Prepared by
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                          v-on:dblclick="updateField('prepared_by')"
                        >
                          <template v-if="updateDetailDialog.prepared_by">
                            <v-text-field
                              ref="prepared_by"
                              v-model.trim="updateDetail.prepared_by"
                              dense
                              filled
                              color="cyan"
                              label="Prepared by"
                              :rules="[
                                validateRules.minLength(
                                  updateDetail.prepared_by,
                                  'Prepared by',
                                  1
                                ),
                                validateRules.maxLength(
                                  updateDetail.prepared_by,
                                  'Prepared by',
                                  100
                                )
                              ]"
                              solo
                              flat
                              hide-details
                              :loading="particuleFieldLoading"
                              :disabled="particuleFieldLoading"
                              @keydown.esc="onEscParticularField"
                              @keydown.enter="updateParticularField"
                            ></v-text-field>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation
                              }"
                              >{{ detail.prepared_by }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <tr class="custom-border-top">
                        <td
                          class="font-size-18 py-1 custom-border-right pr-4"
                          width="200"
                        >
                          Created date
                        </td>
                        <td
                          v-if="detail.sent_date"
                          class="font-size-18 px-4 py-1 custom-border-right"
                          width="200"
                        >
                          Sent date
                        </td>
                        <td class="font-size-18 px-4 py-1" width="200">
                          Open till
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="200"
                          class="font-weight-600 font-size-18 py-1 pr-4 custom-border-right"
                          v-on:dblclick="updateField('date')"
                        >
                          <template v-if="updateDetailDialog.date">
                            <v-menu
                              v-model="createdDatePicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              @keydown.esc="onEscParticularField"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  ref="date"
                                  dense
                                  filled
                                  flat
                                  label="Date"
                                  hide-details
                                  solo
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :value="getFormattedDate(updateDetail.date)"
                                  color="cyan"
                                  :loading="particuleFieldLoading"
                                  :disabled="particuleFieldLoading"
                                  @keydown.esc="onEscParticularField"
                                  @keydown.enter="updateParticularField"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model.trim="updateDetail.date"
                                color="cyan"
                                @keydown.esc="onEscParticularField"
                                v-on:input="updateParticularField"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="cyan"
                                  @click="createdDatePicker = false"
                                >
                                  Cancel
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation
                              }"
                              >{{ formatDate(detail.date) }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                        <td
                          width="200"
                          v-if="detail.sent_date"
                          class="font-weight-600 font-size-18 py-1 px-4 custom-border-right"
                          v-on:dblclick="updateField('sent_date')"
                        >
                          <template v-if="updateDetailDialog.sent_date">
                            <v-menu
                              v-model="sentDatePicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              @keydown.esc="onEscParticularField"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  ref="sent_date"
                                  dense
                                  filled
                                  flat
                                  label="Sent date"
                                  hide-details
                                  solo
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :value="
                                    getFormattedDate(updateDetail.sent_date)
                                  "
                                  color="cyan"
                                  :loading="particuleFieldLoading"
                                  :disabled="particuleFieldLoading"
                                  @keydown.esc="onEscParticularField"
                                  @keydown.enter="updateParticularField"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model.trim="updateDetail.sent_date"
                                color="cyan"
                                @keydown.esc="onEscParticularField"
                                v-on:input="updateParticularField"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="cyan"
                                  @click="sentDatePicker = false"
                                >
                                  Cancel
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation
                              }"
                              >{{ formatDate(detail.sent_date) }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                        <td
                          width="200"
                          class="font-weight-600 font-size-18 py-1 px-4"
                          v-on:dblclick="updateField('open_till')"
                        >
                          <template v-if="updateDetailDialog.open_till">
                            <v-menu
                              v-model="openTillPicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              @keydown.esc="onEscParticularField"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  ref="open_till"
                                  dense
                                  filled
                                  flat
                                  label="Open till"
                                  hide-details
                                  solo
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :value="
                                    getFormattedDate(updateDetail.open_till)
                                  "
                                  color="cyan"
                                  :loading="particuleFieldLoading"
                                  :disabled="particuleFieldLoading"
                                  @keydown.esc="onEscParticularField"
                                  @keydown.enter="updateParticularField"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model.trim="updateDetail.open_till"
                                color="cyan"
                                @keydown.esc="onEscParticularField"
                                v-on:input="updateParticularField"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="cyan"
                                  @click="openTillPicker = false"
                                >
                                  Cancel
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation
                              }"
                              >{{ formatDate(detail.open_till) }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <template v-if="detail.is_approved && detail.approver">
                        <tr class="custom-border-top">
                          <td
                            class="font-size-18 py-1 custom-border-right pr-4"
                          >
                            Approved On
                          </td>
                          <td class="font-size-18 py-1 pl-4">Approved By</td>
                        </tr>
                        <tr>
                          <td
                            class="font-weight-600 font-size-18 py-1 pr-4 custom-border-right"
                          >
                            {{ formatDateTime(detail.approved_on) }}
                          </td>
                          <td class="font-weight-600 font-size-18 py-1 pl-4">
                            {{ detail.approver.full_name }}
                          </td>
                        </tr>
                      </template>
                    </table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" class="mt-8">
            <v-tabs
              active-class="custom-tab-active"
              v-model="quotationTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent tab-sticky"
            >
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#other"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/quotation.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Overview
              </v-tab>
              <template v-if="detail.revisions && detail.revisions.length > 0">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#revision"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/quotation.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Revisions
                </v-tab>
              </template>
              <template v-if="getPermission('line-item:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#line-item"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="detail.line_items_count"
                    bordered
                    color="orange darken-4"
                    :content="detail.line_items_count"
                  >
                    {{
                      detail.line_items_count > 1 ? "Line Item" : "Line Item"
                    }}
                  </v-badge>
                  <template v-else> Line Item </template>
                </v-tab>
              </template>
              <template v-if="getPermission('job:view')">
                <v-tab
                  v-if="jobId > 0"
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#ticket"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/custom-svg/job.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  Job
                </v-tab>
              </template>
              <template v-if="getPermission('invoice:view')">
                <v-tab
                  v-if="invoiceId > 0"
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#invoice"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/invoice.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Invoice
                </v-tab>
              </template>
              <template v-if="getPermission('history:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#history"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
              </template>
            </v-tabs>
            <v-tabs-items v-model="quotationTab">
              <v-tab-item value="other">
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        v-on:dblclick="updateDetailDialog.description = true"
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Description
                        <UpdateTooltip
                          v-if="canUpdateQuotation"
                        ></UpdateTooltip>
                      </h3>
                    </v-card-title>
                    <v-card-text
                      class="p-6 font-size-16"
                      v-on:dblclick="updateDetailDialog.description = true"
                    >
                      <div
                        v-on:dblclick="updateDetailDialog.description = true"
                      >
                        <editor
                          ref="description"
                          v-model="updateDetail.description"
                          :disabled="
                            pageLoading || !updateDetailDialog.description
                          "
                        />
                      </div>
                      <div
                        v-if="
                          canUpdateQuotation && updateDetailDialog.description
                        "
                        class="text-right custom-border-top py-4 mt-4"
                      >
                        <v-btn
                          class="mx-2 custom-bold-button white--text"
                          color="cyan"
                          :loading="pageLoading"
                          :disabled="pageLoading"
                          v-on:click="updateParticularField"
                        >
                          Save
                        </v-btn>
                        <v-btn
                          :disabled="pageLoading"
                          class="custom-grey-border custom-bold-button"
                          v-on:click="onEscParticularField"
                        >
                          Cancel
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-container>
                <LineItemOnlyView
                  isQuotation
                  type="quotation"
                  :detail.sync="detail"
                  :pageLoading.sync="pageLoading"
                ></LineItemOnlyView>
                <TermConditionDetail
                  type="quotation"
                  :detail.sync="detail"
                  :canUpdate.sync="canUpdateQuotation"
                ></TermConditionDetail>
                <InternalNoteAttachmentDetail
                  type="quotation"
                  :detail.sync="detail"
                  :canUpdate.sync="canUpdateQuotation"
                ></InternalNoteAttachmentDetail>
              </v-tab-item>
              <template v-if="detail.revisions && detail.revisions.length > 0">
                <v-tab-item value="revision">
                  <v-container fluid>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th width="200" class="simple-table-th">
                              Quotation Ref
                            </th>
                            <th class="simple-table-th">Job Title</th>
                            <th width="300" class="simple-table-th">
                              Revised Time
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in detail.revisions"
                            :key="index"
                          >
                            <td class="simple-table-td">
                              <Barcode
                                route="quotation.detail"
                                :id="item.id"
                                :barcode="item.barcode"
                              ></Barcode>
                            </td>
                            <td class="simple-table-td">
                              {{ item.job_title }}
                            </td>
                            <td class="simple-table-td">
                              <TableActivity
                                v-if="!lodash.isEmpty(item.added_by)"
                                :data.sync="item"
                              >
                                <template v-slot:display_name>
                                  {{ item.added_by.display_name }}
                                </template>
                                <template v-slot:date_time>
                                  {{ item.created_at }}
                                </template>
                                <template v-slot:format_date_time>
                                  {{ formatDateTime(item.added_at) }}
                                </template>
                              </TableActivity>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-container>
                </v-tab-item>
              </template>
              <template v-if="getPermission('line-item:view')">
                <v-tab-item value="line-item">
                  <LineItemDetail
                    isQuotation
                    type="quotation"
                    :detail.sync="detail"
                    :pageLoading.sync="pageLoading"
                    v-on:load:parent="loadContent"
                    :canUpdate.sync="canUpdateQuotation"
                  ></LineItemDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('job:view')">
                <v-tab-item v-if="jobId > 0" value="ticket">
                  <InternalJobDetail :job.sync="jobId"></InternalJobDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('invoice:view')">
                <v-tab-item v-if="invoiceId > 0" value="invoice">
                  <InternalInvoiceDetail
                    :invoice.sync="invoiceId"
                  ></InternalInvoiceDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('history:view')">
                <v-tab-item value="history">
                  <InternalHistoryDetail
                    type="quotation"
                    :type_id.sync="detail.id"
                  ></InternalHistoryDetail>
                </v-tab-item>
              </template>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <!-- </perfect-scrollbar> -->
      <Dialog :commonDialog.sync="salesSignatureDialog">
        <template v-slot:title> Signature </template>
        <template v-slot:body>
          <v-row>
            <v-col md="10" class="py-0" offset-md="1">
              <div
                id="signature-pad"
                ref="sales_signature_div"
                class="custom-border-grey-dashed"
              >
                <canvas ref="sales_signature"></canvas>
              </div>
            </v-col>
            <v-col md="10" class="py-0" offset-md="1">
              <p class="float-right m-0">
                <v-btn
                  content="Click here to clear signature"
                  v-tippy="{ arrow: true, placement: 'top' }"
                  icon
                  small
                  class="float-right"
                  v-on:click="sales_signature.clear()"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </p>
            </v-col>
          </v-row>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="validSignature || signatureLoading || pageLoading"
            :loading="signatureLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="submitSalesSignature"
          >
            Submit
          </v-btn>
          <v-btn
            :disabled="signatureLoading || pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="salesSignatureDialog = false"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :commonDialog.sync="approval_dialog">
        <template v-slot:title> Approval Confirmation </template>
        <template v-slot:body>
          <v-row>
            <v-col md="10" class="py-0" offset-md="1">
              Are you sure, you want to approve this quotation ?</v-col
            >
          </v-row>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="approval_loading"
            :loading="approval_loading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="approveQuotation()"
          >
            Yes! Approve
          </v-btn>
          <v-btn
            :disabled="approval_loading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="approval_dialog = false"
          >
            No! Cancel
          </v-btn>
        </template>
      </Dialog>
      <template v-if="customerPropertyDialog">
        <CustomerPropertyDialog
          :customerPropertyDialog.sync="customerPropertyDialog"
          :customer.sync="customerId"
          v-on:closeDialog="customerPropertyDialog = false"
          v-on:resetAll="resetAll"
          v-on:selectCustomerProperty="selectCustomerProperty"
        ></CustomerPropertyDialog>
      </template>
    </template>
  </DetailTemplate>
</template>

<script>
import DetailQuotationMixin from "@/core/lib/quotation/detail.quotation.mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import LineItemDetail from "@/view/pages/partials/Detail/Line-Item-Detail.vue";
import TermConditionDetail from "@/view/pages/partials/Detail/Term-Condition-Detail.vue";
import InternalNoteAttachmentDetail from "@/view/pages/partials/Detail/Internal-Note-Attachment-Detail.vue";
import InternalJobDetail from "@/view/pages/partials/Detail/Internal-Job-Detail.vue";
import InternalInvoiceDetail from "@/view/pages/partials/Detail/Internal-Invoice-Detail.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import UpdateTooltip from "@/view/pages/partials/UpdateTooltip.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import LineItemOnlyView from "@/view/pages/partials/Line-Item-Only-View.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  mixins: [DetailQuotationMixin],
  components: {
    editor: TinyMCE,
    Barcode,
    Dialog,
    UpdateTooltip,
    TableActivity,
    DetailTemplate,
    CustomStatus,
    LineItemDetail,
    LineItemOnlyView,
    CustomerPropertyDialog,
    TermConditionDetail,
    InternalNoteAttachmentDetail,
    InternalJobDetail,
    InternalInvoiceDetail,
    InternalHistoryDetail
  }
};
</script>
